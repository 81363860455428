import React from 'react'
import { Section, Container, Content, Columns, Column, Image } from 'bloomer'

const Intro = ({ content }) => {
  return (
    <Section id="section-intro">
      <Container>
        <Content>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Content>
        <Columns isVCentered>
          <Column isSize="1/2" hasTextAlign="centered">
            <Image src="https://res.cloudinary.com/turbinekreuzberg/image/upload/t_txb_preview/v1529574035/devday18/berlin/20180526_170249.jpg" />
            That was a close one: Ryan Townsend snatches the Best Speaker Award
            in Berlin
          </Column>
          <Column isSize="1/2" hasTextAlign="centered">
            <Image src="https://res.cloudinary.com/turbinekreuzberg/image/upload/t_txb_preview/v1529574279/devday18/faro/33576390_2135113823412062_9160620365636960256_o.jpg" />
            Daniel Speckhardt and Philippos Kardaras receive the first printed
            part of the Best Speaker Award in Faro
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default Intro
