import React from 'react'
import { Hero, HeroBody, Container } from 'bloomer'
import keyVisual from './img/devday18-keyvisual.svg'

const MasterTeaser = ({ content }) => {
  const bodyStyles = {
    backgroundImage: 'url(' + keyVisual + ')',
    backgroundColor: '#0000FF',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '40%',
    backgroundPositionX: '50%',
  }
  return (
    <Hero
      id="section-master-teaser"
      isSize="large"
      isColor="primary"
      className="bg-texture"
    >
      <HeroBody>
        <img src={keyVisual} className="key-visual" />
        <Container>
          <h1>
            Dev Day 2018: <b>Revisited</b>
            <small>Celebrating Software Craftsmanship</small>
          </h1>
          <h2>2018 / 05 / 26</h2>
        </Container>
      </HeroBody>
    </Hero>
  )
}

export default MasterTeaser
