import React from 'react'
import Helmet from 'react-helmet'

import AppFooter from './Footer'

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet
        title="Dev Day 2018: Revisited"
        bodyAttributes={{
          class: 'site-2018',
        }}
      >
        <meta
          property="og:title"
          content="Dev Day 2018 in Berlin and Faro: Revisited (05/26/2018)"
        />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://devday.io/" />
        <meta
          property="og:description"
          content="A wide range of topics, an exclusive choice of speakers, a day to remember for developers: that was Dev Day 2018. We heard 11 amazing talks, e.g. on Kubernetes setups, website and application optimization, command line tooling or the Elixir programming language."
        />
        <meta
          property="og:image"
          content="https://devday.io/img/devopengraph.jpg"
        />
      </Helmet>
      {children}
      <AppFooter />
    </div>
  )
}

export default Layout
