import React from 'react'
import { graphql, withPrefix } from 'gatsby'

import Layout from '../components/2018/layout2018'

import AppHeader from '../components/2018/Header/Header'
import MasterTeaser from '../components/2018/MasterTeaser'
import StatsRibbon from '../components/2018/StatsRibbon'
import Talks from '../components/2018/Talks'
import Impressions from '../components/common/Impressions'
import Newsletter from '../components/2018/Newsletter'
import CodingChallenge from '../components/2018/CodingChallenge/CodingChallenge'
import Intro from '../components/2018/Intro'
import { Section, Container, Button, Title } from 'bloomer'

import _groupBy from 'lodash.groupby'

import('../components/2018/style/all.sass')
//import('font-awesome/css/font-awesome.css')

const IndexPage = ({ location, data }) => {
  const nodes = data.talks.edges
    .filter(e => e.node.location != null)
    .map(e => e.node)
  const talksByLocale = _groupBy(nodes, 'node_locale')
  const talks = talksByLocale['de']

  return (
    <Layout>
      <AppHeader color="" navbar="main" upColor="is-transparent" />

      <MasterTeaser content={data.masterTeaser.html} />
      <StatsRibbon />
      <Intro content={data.about.html} />

      <Talks talks={talks} />

      <CodingChallenge
        leaderBoard={data.leaderBoard.edges}
        challenges={data.challenges.edges}
        prizes={data.amazonPrizes.edges}
      />
      <Section id="section-impressions">
        <a name="impressions" />

        <Container>
          <Title isSize={2}>Impressions</Title>
          <Impressions
            button={Button}
            buttonProps={{ isFullWidth: 1 }}
            images={data.impressions.edges.map(({ node }) => node)}
          />
        </Container>
      </Section>

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    masterTeaser: markdownRemark(fields: { slug: { eq: "/masterTeaser/" } }) {
      id
      html
      frontmatter {
        path
        date
      }
    }

    about: markdownRemark(fields: { slug: { eq: "/about/" } }) {
      html
      id
      frontmatter {
        title
      }
    }

    impressions: allCloudinaryImage(
      filter: { public_id: { regex:"/devday18\//" } }
      sort: { fields: [bytes], order: ASC }
    ) {
      edges {
        node {
          width
          height
          secure_url
          public_id
          type
          format
          scaled_image_url
          eco_image_url
          thumb_dims {
            w
            h
          }
          context {
            custom {
              alt
              caption
            }
          }
          tags
        }
      }
    }

    leaderBoard: allCodingChallengeLeaderboardJson {
      edges {
        node {
          user
          time
          score
          country
        }
      }
    }

    challenges: allCodingChallengeChallengesJson {
      edges {
        node {
          name
          path
          difficulty
        }
      }
    }

    amazonPrizes: allCodingChallengePrizesJson {
      edges {
        node {
          title
          author
          imageUrl
          link
          asin
        }
      }
    }

    talks: allContentfulTalk(
      filter: { year: { eq: 2018 }, node_locale: { eq: "de" } }
      sort: { fields: [slotTime], order: ASC }
    ) {
      edges {
        node {
          id
          node_locale
          title
          slotTime
          location
          youtubeUrl
          slides
          winner
          highlights {
            highlights
          }
          statement {
            statement
          }
          abstract {
            childMarkdownRemark {
              html
            }
          }
          speaker {
            id
            name
            position
            company
            twitterHandle
            image {
              title
              fixed(width: 320) {
                width
                height
                src
              }
            }
            bio {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`

/*
allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
          	title
          }
          html
        }
      }
    }
    */
