import React from 'react'
import {
  Footer,
  Container,
  Columns,
  Column,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  LevelItem,
  Title,
} from 'bloomer'
import Link from 'gatsby-link'
import txb_logo from './img/txb_logo.svg'

const AppFooter = () => (
  <div>
    <Footer className="footer">
      <Container>
        <Title isSize={2} className="has-text-centered has-text-white">
          Contact Us
        </Title>
        <Columns>
          <Column hasTextAlign="centered">
            <a
              href="https://turbinekreuzberg.com"
              target="_blank"
              className="txb-logo"
            >
              <img src={txb_logo} />
            </a>
          </Column>
          <Column hasTextAlign="centered">
            <Icon isSize="large" className="fa fa-phone fa-lg" /> <br />
            +49 30 2847 2640 - 0
          </Column>
          <Column hasTextAlign="centered">
            <Icon isSize="large" className="fa fa-envelope fa-lg" /> <br />
            <a
              className="white"
              href="mailto:devday@turbinekreuzberg.com"
              target="_blank"
            >
              devday@turbinekreuzberg.com
            </a>
          </Column>
        </Columns>
      </Container>
    </Footer>

    <Footer className="final-footer">
      <Container isFluid={true}>
        <Level>
          <LevelLeft>
            <LevelItem>
              <Link to="/">Home</Link>
            </LevelItem>
            <LevelItem>
              <Link to="/imprint" activeStyle={{ fontWeight: 'bold' }}>
                Imprint
              </Link>
            </LevelItem>
            <LevelItem>
              <Link to="/privacy-policy" activeStyle={{ fontWeight: 'bold' }}>
                Privacy Policy
              </Link>
            </LevelItem>
            <LevelItem>
              <Link to="/code-of-conduct" activeStyle={{ fontWeight: 'bold' }}>
                Code of Conduct
              </Link>
            </LevelItem>
          </LevelLeft>
          <LevelRight>
            <LevelItem href="https://turbinekreuzberg.com" target="_blank">
              <Icon className="fa fa-copyright" />
              2018-2020 Turbine Kreuzberg
            </LevelItem>
          </LevelRight>
        </Level>
      </Container>
    </Footer>

    <Footer className="love-footer">
      <Container isFluid={true} hasTextAlign="centered">
        made with burgers and beer, bulma.io, gatsby.js, react.js. headless
        content by contentful, cloudinary. hosted &amp; continuously deployed on
        gitlab &amp; netlify.
      </Container>
    </Footer>
  </div>
)

export default AppFooter
