import React from 'react'

import { NavbarMenu, NavbarStart, NavbarEnd } from 'bloomer'

import { Link, animateScroll as scroll } from 'react-scroll'

export default props => (
  <NavbarMenu isActive={props.isActive} onClick={props.onClickNav}>
    <NavbarStart />

    <NavbarEnd>
      <Link
        className="navbar-item"
        activeClass="active"
        to="talks"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        Talks
      </Link>

      <Link
        className="navbar-item"
        activeClass="active"
        to="coding-challenge"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        Coding Challenge
      </Link>

      <Link
        className="navbar-item"
        activeClass="active"
        to="impressions"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        Impressions
      </Link>
    </NavbarEnd>
  </NavbarMenu>
)
