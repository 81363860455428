import React from 'react'
import { NavbarMenu, NavbarEnd } from 'bloomer'
import Link from 'gatsby-link'

export default props => (
  <NavbarMenu isActive={props.isActive}>
    <NavbarEnd>
      <Link className="navbar-item" to="/">
        Home
      </Link>
      <Link
        className="navbar-item"
        to="/imprint"
        activeStyle={{ fontWeight: 'bold' }}
      >
        Imprint
      </Link>
      <Link
        className="navbar-item"
        to="/privacy-policy"
        activeStyle={{ fontWeight: 'bold' }}
      >
        Privacy Policy
      </Link>
      <Link
        className="navbar-item"
        to="/code-of-conduct"
        activeStyle={{ fontWeight: 'bold' }}
      >
        Code of Conduct
      </Link>
    </NavbarEnd>
  </NavbarMenu>
)
