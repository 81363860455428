import React, { Children } from 'react'
import { Navbar, NavbarBrand, NavbarItem, NavbarBurger, Icon } from 'bloomer'

import devdayLogo from '../img/devday_white.svg'

import MainNavbar from './MainNavbar'
import StaticNavbar from './StaticNavbar'
import { Link } from 'gatsby'

export default class AppHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      scrollPos: 0,
      scrollListener: this.handleScroll.bind(this),
    }
    this.onClickNav = this.onClickNav.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.state.scrollListener)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.state.scrollListener)
  }

  handleScroll(e) {
    this.setState({ scrollPos: window.scrollY })
  }

  onClickNav() {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }))
  }

  render() {
    return (
      <Navbar
        className={
          (this.state.scrollPos < 400 ? this.props.upColor : 'is-small') +
          ' is-fixed-top ' +
          this.props.color
        }
      >
        <NavbarBrand>
          <Link to="/">
            <NavbarItem className="logo_comp" style={{ background: 'none' }}>
              <img className="logo_comp_dev" src={devdayLogo} />
            </NavbarItem>
          </Link>
        </NavbarBrand>
        <NavbarBurger
          isActive={this.state.isActive}
          onClick={this.onClickNav}
        />
        {this.props.navbar == 'static' ? (
          <StaticNavbar isActive={this.state.isActive} />
        ) : (
          <MainNavbar
            isActive={this.state.isActive}
            onClick={this.onClickNav}
          />
        )}
      </Navbar>
    )
  }
}
