import React, { Children } from 'react'
import {
  Container,
  Title,
  Subtitle,
  Hero,
  Box,
  HeroBody,
  Button,
  Icon,
  Image,
  Column,
  Columns,
  Delete,
  Level,
  LevelLeft,
  LevelItem,
  Modal,
  ModalBackground,
  ModalContent,
  ModalClose,
  Media,
  MediaContent,
  MediaLeft,
  MediaRight,
  Content,
} from 'bloomer'
import ResponsiveEmbed from 'react-responsive-embed'
import { animateScroll as scroll, scroller } from 'react-scroll'

const SpeakerModal = ({ speaker, onClose }) => {
  return (
    <Modal isActive>
      <ModalBackground onClick={onClose} />
      <ModalContent>
        <Box>
          <Media>
            <MediaLeft>
              <Image isSize="96x96" src={speaker.image.fixed.src} />
              <p className="has-text-centered">
                {speaker.twitterHandle}
                {speaker.twitterHandle && (
                  <a
                    href={`https://twitter.com/${speaker.twitterHandle}`}
                    target="_blank"
                  >
                    @{speaker.twitterHandle}
                  </a>
                )}
              </p>
            </MediaLeft>
            <MediaContent>
              <Content>
                <strong>{speaker.name}</strong>
                <div
                  dangerouslySetInnerHTML={{
                    __html: speaker.bio.childMarkdownRemark.html,
                  }}
                />
              </Content>
            </MediaContent>
            <MediaRight>
              <Delete onClick={onClose} />
            </MediaRight>
          </Media>
        </Box>
      </ModalContent>
      <ModalClose onClick={onClose} />
    </Modal>
  )
}

class Speaker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      speakerModal: false,
    }
  }
  render() {
    const speaker = this.props.speaker
    return (
      <div className="speaker">
        <Image isSize="96x96" src={speaker.image.fixed.src} />
        <a onClick={() => this.setState({ speakerModal: speaker })}>
          {speaker.name}
        </a>
        <p>
          {speaker.position} <br />
          {speaker.company}
        </p>
        {this.state.speakerModal && (
          <SpeakerModal
            speaker={this.state.speakerModal}
            onClose={() => this.setState({ speakerModal: false })}
          />
        )}
      </div>
    )
  }
}

class Talk extends React.Component {
  render() {
    const flag = this.props.talk.location == 'Faro' ? 'por' : 'ger'
    let spkidx = 1
    const speakersHeader = this.props.talk.speaker.map(s => {
      return (
        <div className="speaker" key={`spkHdr-${spkidx++}`}>
          <span className="speaker-name">{s.name}</span>
          <small className="speaker-company">{s.company}</small>
        </div>
      )
    })

    const speakers = this.props.talk.speaker.map(s => (
      <Speaker speaker={s} key={s.id} />
    ))

    return (
      <article
        className={
          'element accordion ' + (this.props.isActive ? 'is-active' : '')
        }
        id={'talk-' + this.props.talk.id}
      >
        <div
          className="accordion-header toggle"
          onClick={() => this.props.onOpen(this.props.talk.id)}
        >
          <Level isMarginless>
            <LevelLeft>
              <LevelItem>
                <Subtitle tag="div" isSize={5}>
                  {this.props.talk.title}
                  {speakersHeader}
                </Subtitle>
              </LevelItem>
            </LevelLeft>
          </Level>
          <button className="toggle" aria-label="toggle" />
        </div>
        <div className="accordion-body">
          <div className="accordion-content">
            {this.props.talk.youtubeUrl && this.props.isActive && (
              <ResponsiveEmbed
                src={this.props.talk.youtubeUrl}
                allowFullScreen
              />
            )}
            <Columns>
              <Column isSize="2/3">
                <Content>
                  <blockquote>
                    &ldquo;
                    {this.props.talk.highlights.highlights}
                    &rdquo;
                  </blockquote>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.talk.abstract.childMarkdownRemark.html,
                    }}
                  />
                  <blockquote>
                    &ldquo;
                    {this.props.talk.statement.statement}
                    &rdquo;
                  </blockquote>
                </Content>
              </Column>
              <Column className="speakers">
                {speakers}
                {this.props.talk.slides && (
                  <Button
                    isColor="danger"
                    href={this.props.talk.slides}
                    target="_blank"
                  >
                    <Icon isSize="large" className="fa fa-chevron-right" />
                    &nbsp; view the slides
                  </Button>
                )}
              </Column>
            </Columns>
          </div>
        </div>
      </article>
    )
  }
}

export default class Talks extends React.Component {
  constructor(props) {
    super(props)
    const winnerTalks = props.talks.filter(t => t.winner)
    this.state = {
      activeTalkId: winnerTalks[0].id,
    }
    this.handleOpened = this.handleOpened.bind(this)
  }

  handleOpened(talkId) {
    if (this.state.activeTalkId == talkId) {
      this.setState({ activeTalkId: null })
    } else {
      this.setState({ activeTalkId: talkId })
    }

    //yep that's ugly.
    setTimeout(() => {
      scroller.scrollTo(`talk-${talkId}`, {
        offset: -100,
        duration: 400,
        smooth: 'easeInOut',
      })
    }, 450)
  }

  render() {
    const listItems = this.props.talks.map(talk => {
      return (
        <Talk
          key={talk.id}
          talk={talk}
          isActive={talk.id == this.state.activeTalkId}
          onOpen={this.handleOpened}
        />
      )
    })

    return (
      <Hero isColor="dark">
        <HeroBody>
          <Container>
            <a name="talks" />
            <Title isSize={2}>Talks</Title>
            <section className="accordions" id="section-talks">
              {listItems}
            </section>
          </Container>
        </HeroBody>
      </Hero>
    )
  }
}
